import React, { useCallback, useState } from 'react'
import { Navigation } from "../../components/Navigation";
import { Navigation_loggedin } from "../../components/Navigation_loggedin";
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom'
import { useAuth0 } from "@auth0/auth0-react";  // <-- Import useAuth0 hook
import { useNavigate } from 'react-router-dom'
import "./style.css";

export const Profile = () => {

  useEffect(() => {
    fetchUserCredits();
    fetchPaymentHistory();
  }, []);

    //---------------------------------auhentication code----------------------------------------
    const { getAccessTokenSilently, isAuthenticated, loginWithRedirect, logout, user, isLoading } = useAuth0();
    //---------------------------------auhentication code----------------------------------------
    const [selectedOption, setSelectedOption] = useState('Profile');
    const location = useLocation();
    const data = location.state?.data;
    const navigate = useNavigate();
 
    const [paymentHistory, setPaymentHistory] = useState([]);
    const [credits, setCredits] = useState(" "); // Initialize credits state
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [subscriptionEnd, setSubscriptionEnd] = useState(" ");
    const [subscriptionCancelled, setSubscriptionCancelled] = useState(false);
  // console.log(data.time_stamp)
    useEffect(() => {
      if(isSubscribed === true){
        fetchUserSubscription();
      }
    }, [isSubscribed]);

    
    const fetchUserCredits = async () => {
      // send a POST request
      console.log('Send POST request to API')
      //---------------------------------auhentication code----------------------------------------
      //get user's specific token
      const token = await getAccessTokenSilently();
      //---------------------------------auhentication code----------------------------------------
      fetch('/resumasterapi/check_user_status', { //api endpoint
        method: 'POST',
        //---------------------------------auhentication code----------------------------------------
        headers: {
          Authorization: `Bearer ${token}`
        },
      //---------------------------------auhentication code----------------------------------------
      }).then(response => {
        // Check for the 400 status code 
        // console.log('Server Response:', response);
        if (response.status === 400) {
            throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
        }
        if (response.status == 500) {
            throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
        }
        //---------------------------------auhentication code----------------------------------------
        if (response.status == 401) {
            throw new Error('Sorry, you must login first')
        }
        //---------------------------------auhentication code----------------------------------------
        return response.json();
      }).then(data => {
        if (data.in_subscription === true){
          setCredits("Monthly Subscription");
          setIsSubscribed(true);
        }
        else { 
          setCredits(data.current_credits);
          //console.log(data.current_credits);
        }
        //setLoading(false);
      }).catch(error => {
        // handle the error
        console.error("fetch user credits failed:", error);
        // If there's an error, ensure loading is turned off
        // Check if the error is due to a JSON parsing issue
        if (error.message.includes('Unexpected token')) {
          console.error('Received non-JSON response:', error);
          // You might want to set a different state here to handle this specific error
      }
        //setLoading(false);
        alert(error.message);  // Displaying the error message to the user
      });
    }

    // Function to handle subscription cancellation
    const handleCancelSubscription = async () => {
      console.log('Cancel subscription');
      closeModal();

      // send a POST request
      console.log('Send POST request to API')
      //---------------------------------auhentication code----------------------------------------
      //get user's specific token
      const token = await getAccessTokenSilently();
      //---------------------------------auhentication code----------------------------------------
      fetch('/resumasterapi/cancel-subscription', { //api endpoint
        method: 'POST',
        //---------------------------------auhentication code----------------------------------------
        headers: {
          Authorization: `Bearer ${token}`
        },
      //---------------------------------auhentication code----------------------------------------
      }).then(response => {
        // Check for the 400 status code 
        // console.log('Server Response:', response);
        if (response.status === 400) {
            throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
        }
        if (response.status == 500) {
            throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
        }
        //---------------------------------auhentication code----------------------------------------
        if (response.status == 401) {
            throw new Error('Sorry, you must login first')
        }
        //---------------------------------auhentication code----------------------------------------
        return response.json();
      }).then(data => {
        console.log(data.status);
        if(data.status == "success"){
          setSubscriptionCancelled(true);
          setIsSubscribed(false);
          console.log(subscriptionCancelled);
          console.log(isSubscribed);
        }
      }).catch(error => {
        // handle the error
        console.error("handle subscription cancel failed:", error);
        if (error.message.includes('Unexpected token')) {
          console.error('Received non-JSON response:', error);
          // You might want to set a different state here to handle this specific error
      }
        //setLoading(false);
        alert(error.message);  // Displaying the error message to the user
      });
    };

    const fetchUserSubscription = async () => {
      // send a POST request
      console.log('Send POST request to API')
      //---------------------------------auhentication code----------------------------------------
      //get user's specific token
      const token = await getAccessTokenSilently();
      //---------------------------------auhentication code----------------------------------------
      fetch('/resumasterapi/get_subscription_info', { //api endpoint
        method: 'POST',
        //---------------------------------auhentication code----------------------------------------
        headers: {
          Authorization: `Bearer ${token}`
        },
      //---------------------------------auhentication code----------------------------------------
      }).then(response => {
        // Check for the 400 status code 
        // console.log('Server Response:', response);
        if (response.status === 400) {
            throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
        }
        if (response.status == 500) {
            throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
        }
        //---------------------------------auhentication code----------------------------------------
        if (response.status == 401) {
            throw new Error('Sorry, you must login first')
        }
        //---------------------------------auhentication code----------------------------------------
        return response.json();
      }).then(data => {
        console.log(data.current_period_end);
        setSubscriptionEnd(data.current_period_end);
        if(data.cancel_at_period_end === true){
          setSubscriptionCancelled(true);
          setIsSubscribed(false);
        }
        //setLoading(false);
      }).catch(error => {
        // handle the error
        console.error("fetch user credits failed:", error);
        // If there's an error, ensure loading is turned off
        // Check if the error is due to a JSON parsing issue
        if (error.message.includes('Unexpected token')) {
          console.error('Received non-JSON response:', error);
          // You might want to set a different state here to handle this specific error
      }
        //setLoading(false);
        alert(error.message);  // Displaying the error message to the user
      });
    }
    
    

    const fetchPaymentHistory = async () => {
      // send a POST request
      console.log('Send POST request to API')
      //---------------------------------auhentication code----------------------------------------
      //get user's specific token
      const token = await getAccessTokenSilently();
      //---------------------------------auhentication code----------------------------------------
      fetch('/resumasterapi/check_payment_history', { //api endpoint
        method: 'POST',
        //---------------------------------auhentication code----------------------------------------
        headers: {
          Authorization: `Bearer ${token}`
        },
        //---------------------------------auhentication code----------------------------------------
      }).then(response => {
        // Check for the 400 status code 
        // console.log('Server Response:', response);
        if (response.status === 400) {
            throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
        }
        if (response.status == 500) {
            throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
        }
        //---------------------------------auhentication code----------------------------------------
        if (response.status == 401) {
            throw new Error('Sorry, you must login first')
        }
        //---------------------------------auhentication code----------------------------------------
        return response.json();
      }).then(data => {
        if (data && data.payment_info) {
          setPaymentHistory(data.payment_info);
        }
        //update setapiresponse that is used in result.js
        //setApiResponse(data);
        //stop loading and redirect to /result
        //setLoading(false);
        //history.push("/result");
      }).catch(error => {
        // handle the error
        console.error("fetch payment history failed:", error);
        // If there's an error, ensure loading is turned off
        // Check if the error is due to a JSON parsing issue
        if (error.message.includes('Unexpected token')) {
          console.error('Received non-JSON response:', error);
          // You might want to set a different state here to handle this specific error
      }
        //setLoading(false);
        alert(error.message);  // Displaying the error message to the user
      });
    };

    const plans = [
      { id: 1, name: 'subscription', description: 'Monthly Subscription' },
      { id: 2, name: 'plan-a', description: '1000 ResuCredits'},
      { id: 3, name: 'plan-b', description: '500 ResuCredits' },
      { id: 4, name: 'plan-c', description: '100 ResuCredits'},
    ];

    if (!isAuthenticated) {
        return <div>Please log in to view your profile.</div>;
    }
  
    const handleOptionChange = (option) => {
        setSelectedOption(option);
    };

    const handleClick = (data, index) => {
        // Navigate to /home and pass state
        navigate('/new_resume_revision_history', { state: { data, index } });
    };

    const handleClick_coverletter = (data, index) => {
        // Navigate to /home and pass state
        navigate('/coverletter_history', { state: { data, index } });
    };

    const purchasedPlan = plans.find(plan => plan.name.toString() === "plan-a" );


    // Function to show the modal
    const openModal = () => {
      setIsModalOpen(true);
      console.log(isModalOpen);
    };

    // Function to close the modal
    const closeModal = () => {
        setIsModalOpen(false);
    };

  
    return (
      <div className='profile-container'>
        <Helmet>
        <title>Profile - ResuMaster</title>
        <meta property="og:title" content="Profile - ResuMaster" />
        </Helmet>
        {
        isAuthenticated ? (
            <Navigation_loggedin className="navigation-2202" />
          ) : (
            <Navigation className="navigation-2202" />
          )
        }
        <div className="sidebar-container">
            <button className={selectedOption === 'Profile' ? 'sidebar-button active' : 'sidebar-button'} onClick={() => handleOptionChange('Profile')}> 
                &#128100; Profile
            </button>
            {/*============================== temporary comment out for deployment 06/09===================================*/}
            <button className={selectedOption === 'Payment' ? 'sidebar-button active' : 'sidebar-button'} onClick={() => handleOptionChange('Payment')}>
                &#128179; Payment
            </button>
            {/*============================== temporary comment out for deployment 06/09===================================*/}
        </div>

        <div className="content-container">
          <Link to="/" className="profile-back-button">
            <img className="back-button-img" src='/img/chevron-left.png' />
            <div className="back-button-text">Home</div>
          </Link>

          {selectedOption && (
            <div className='main-content'>
                <div className='content-title'>
                    <h2>My {selectedOption}</h2>
                </div>
                {/* <a className="button-edit" href="/profile">
                  <img className="edit-icon" src="/img/edit_icon.png" />
                </a> */}

                {/* Render content based on the selectedOption */}

                {selectedOption === 'Profile' && 
                <div className='profile-content'>
                  <div className="user-info">
    
                    <div className="userinfo1">
                        <span className="label">My Username:</span>
                        <span className="value"> {user.name}</span>
                    </div>
                    <div className="userinfo1">
                        <span className="label">Email:</span>
                        <span className="value"> {user.email}</span>
                    </div>
                  </div>
                  
                  <div className="resume-history">
                  <div className="profile-sub-header">My Resumes</div>
                    <div className="resume-container">
                        {data.time_stamp.map((history, index) => (
                            <div className="resume-box">
                                <a className="history-link" href="#" onClick={(e) => {
                                    e.preventDefault(); // Prevent default anchor behavior
                                    handleClick(data, index);
                                }}>
                                {history}
                                </a>
                            </div>
                        ))}
                    </div>
                  </div>

                  <div className="coverletter-history">
                    <div className="profile-sub-header">My Cover Letters</div>
                    <div className="resume-container">
                        {data.coverletter_history.time_stamp.map((history, index) => (
                            <div className="resume-box">
                                <a className="history-link" href="#" onClick={(e) => {
                                    e.preventDefault(); // Prevent default anchor behavior
                                    handleClick_coverletter(data.coverletter_history, index);
                                }}>
                                {history}
                                </a>
                            </div>
                        ))}
                    </div>
                  </div>
                  
                  <div className="logout">
                  
                    <button className="logout-button" onClick={logout}>
                      <div className="logout-buttontext">Log out</div>
                    </button>

                  </div>

                </div>
                }

                {selectedOption === 'Payment' && 
                  <div className="Membership-content">
                    <div className="current-plan">
                      <div className="profile-sub-header">
                        <div>Current Credits: {credits}</div>
                        <div>
                          {/* Render subscription renew date*/}
                          {isSubscribed ? (
                                <div className="renew-date-text">Renew: {subscriptionEnd}</div>
                          ) : ( <p> </p> )
                          }</div>

                          {subscriptionCancelled ? (
                          <div>
                            {/* Render cancel confirmation & end date */}
                            <div className="renew-date-text">Your subscription has been cancelled, enjoy unlimited credits until {subscriptionEnd}</div>
                          </div>
                           ) : ( <p> </p> )
                        }
                      </div>
                    </div>
                    <div className="profile-links-container">
                      <div className="cancel-subscription-btn-container">
                        {/* Render cancel subscription button if subscribed */}
                        {isSubscribed ? (
                                <button className="cancel-subscription-btn" onClick={openModal}>Cancel Subscription</button>
                          ) : ( <p> </p> )
                          }
                        </div>
                        <Link className="link-plans-container" to="/plan">
                            <div className="link-plans">See all plans</div>
                        </Link>
                    </div>

                    <div className="payment-history">
                      <div className="payment-history-header">Payment History</div>
                    </div>
                    
                    <div className="history-table-container">
                      <table className="history-table">
                        <thead>
                          <tr>
                            <th className="column-title">Date</th>
                            <th className="column-title">Credits</th>
                            <th className="column-title">Payment Amount</th>
                          </tr>
                        </thead>
                        <tbody className="history-table-content">
                          {paymentHistory.map((row, index)=> (
                            <tr key={index}>
                              <td>{row.time_stamp}</td>
                              <td>{row.Item}</td>
                              <td>${row.price}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    {isModalOpen && (
                    <div className="modal-profile">
                      {console.log("Modal is now open")} {/* Console log here */}
                      <div className="modal-profile-content">
                          <h2>Confirm Cancellation</h2>
                          <p>Are you sure you want to cancel your subscription?</p>
                          <button className="confirm-button-yes" onClick={handleCancelSubscription}>Yes</button>
                          <button className="confirm-button-no" onClick={closeModal}>No</button>
                      </div>
                    </div>
                  )}
                  </div>
                }
              </div>
              )
            }

          </div>

    
      </div>
    );
  };
