import React from 'react';
import { useNavigate } from 'react-router-dom';
import './style_currentR.css'; 

const CurrentResume = ({ data, removeResumeHandler}) => {
    if (!data ) {
        return <p>No resume record found.</p>;
    }
    const navigate = useNavigate();
    let parsedData;

    try {
        parsedData = JSON.parse(data);  // Convert JSON string to an object
    } catch (error) {
        console.error("Failed to parse JSON:", error);
        parsedData = {};  // Default to an empty object if parsing fails
    }
    
    const handleClick = (selectedSection) => {
        navigate('/dashboardEditor', {
            state: {
                data: parsedData.Resume_info,
                selectedExperienceIndex: 0,
                work_exp_length: parsedData.Resume_info.work_experience ? parsedData.Resume_info.work_experience.length : 0,
                original_work_exp_length: parsedData.Resume_info.work_experience ? parsedData.Resume_info.work_experience.length : 0,
                currentResumeName: parsedData.Resume_name,
                selectedSection: selectedSection
            }
        });
    };
    const { basic_info, education, skills, work_experience, project_experience, certificate } = parsedData.Resume_info || {};

    const formatDateRange = (startMonth, startYear, endMonth, endYear) => {
        const monthMap = {
            1: "January",
            2: "February",
            3: "March",
            4: "April",
            5: "May",
            6: "June",
            7: "July",
            8: "August",
            9: "September",
            10: "October",
            11: "November",
            12: "December",
        };
    
        // Convert startMonth, endMonth, startYear, and endYear to integers in case they are strings
        const formattedStartMonth = monthMap[parseInt(startMonth, 10)];
        const formattedEndMonth = monthMap[parseInt(endMonth, 10)];
        const formattedStartYear = parseInt(startYear, 10);
        const formattedEndYear = parseInt(endYear, 10);
    
        return `${formattedStartMonth} ${formattedStartYear} - ${formattedEndMonth} ${formattedEndYear}`;
    };

    const getMonthNumber = (monthName) => {
        const date = new Date(`${monthName} 1, 2000`); 
        return String(date.getMonth() + 1).padStart(2, '0'); 
    };

    
    const formatDuration = (duration) => {
        
        // const [start, end] = duration.split(' - ');
        // const [startMonth, startYear] = start.split(' ');
        // const formattedStart = `${startYear}.${getMonthNumber(startMonth)}`;
        // let formattedEnd;
        // if (end.toLowerCase() === 'current') {
        //     formattedEnd = 'current';
        // } else {
        //     const [endMonth, endYear] = end.split(' ');
        //     formattedEnd = `${endYear}.${getMonthNumber(endMonth)}`;
        // }

        return `${duration}`;
    };

    const formatDate = (dateString) => {
       
        // const [monthName, year] = dateString.split(' ');
        // const date = new Date(`${monthName} 1, ${year}`); 
        // const monthNumber = String(date.getMonth() + 1).padStart(2, '0'); 

        if (!dateString) {
            return "";
        }

        return `${dateString}`; 
    };

    const replaceResumeHandler = () => {
        navigate('/uploadResume');
    };

    return (
        <div>
            <div className='resuName'>
                <h1>{parsedData.Resume_name}</h1>
                <div className="resuName_icons">
                <button className='dashboard-remove-button' onClick={removeResumeHandler}>
                    <img src="/img/resume_delete.png" alt="delete icon" />
                </button>
                <button className='dashboard-remove-button' onClick={replaceResumeHandler}>
                    <img src="/img/resume_dashboard_upload.png" alt="upload icon" />
                </button>
                </div>
                
            </div>
            <p>To edit the current resume, click the sections below. To upload a new one, first delete or replace the current resume.</p>
           
            <div className='Infor' onClick={()=>handleClick('personalDetails')}>
                <div className='personalTitle' >
                    <p className="current-resume-section-header">
                        <img alt="Personal Details Icon" src="/img/resume_personalinformation.png" />
                        Personal Details
                    </p>
                    <div className="dashboard-edit-icon"/>
                </div>
                {basic_info.first_name && basic_info.last_name && (
                    <p>{basic_info.first_name} {basic_info.last_name}</p>
                )}
                {basic_info.email && <p>{basic_info.email}</p>}
                {basic_info.phone_number && <p>{basic_info.phone_number}</p>}
                {basic_info.location && <p>{basic_info.location}</p>}
                {basic_info.personal_website_url && <p>{basic_info.personal_website_url}</p>}

            </div>

            <div className='Infor' onClick={()=>handleClick('education')}>
                <div className='personalTitle' >
                    <p className="current-resume-section-header">
                        <img alt="Education Icon" src="/img/resume_education.png" />
                        Education
                    </p>
                    <div className="dashboard-edit-icon"/>
                    {/* <img src="/img/edit_resume_pen1.png" alt="edit icon" 
                    onClick={()=>handleClick('education')} className="dashboard-edit-icon"/> */}
                </div>
                {education.length > 0 ? (
                    education.map((edu, index) => (
                        <div key={index}>
                            <p className='item'>{edu.university}</p>
                            <p className='item'>{edu.degrees}</p>
                            <p className='item'>{edu.majors}</p>
                            <p className='item'>{formatDateRange(
                                edu.university_start_month,
                                edu.university_start_year,
                                edu.graduation_month,
                                edu.graduation_year)}</p>
                        </div>
                    ))
                ) : (
                    <p>No education information available.</p>
                )}

            </div>

            <div className='Infor' onClick={()=>handleClick('experiences')}>
                <div className='personalTitle' >
                    <p className="current-resume-section-header">
                        <img alt="Experience Icon" src="/img/resume_experience.png" />
                        Experience
                    </p>
                    <div className="dashboard-edit-icon"/>
                    {/* <img src="/img/edit_resume_pen.png" alt="edit icon" onClick={()=>handleClick('experiences')} /> */}

                </div>
                {work_experience.length > 0 ? (
                    work_experience.map((work, index) => (
                        <div key={index}>
                            <p className='item'>{work.job_title} at {work.company}</p>
                            <p className='item'>{formatDuration(work.duration)}</p>
                            <p className='item'>{work.location}</p>
                        </div>
                    ))
                ) : (
                    <p>No work experience available.</p>
                )}

            </div>
            <div className='Infor' onClick={()=>handleClick('projects')}>
                <div className='personalTitle' >
                    <p className="current-resume-section-header">
                        <img alt="Project Icon" src="/img/resume_project.png" />
                        Project
                    </p>
                    <div className="dashboard-edit-icon"/>
                    {/* <img src="/img/edit_resume_pen.png" alt="edit icon" onClick={()=>handleClick('experiences')} /> */}
                </div>
                {project_experience.length > 0 ? (
                    project_experience.map((project, index) => (
                        <div key={index}>
                            {project.institution===null ? <p className='item'>{project.project_name}</p> :<p className='item'>{project.project_name} at {project.institution}</p>}
                            {project.duration &&<p className='item'>{formatDuration(project.duration)}</p>}
                            {project.location &&<p className='item'>{project.location}</p>}
                        </div>
                    ))
                ) : (
                    <p>No project experience available.</p>
                )}

            </div>
            <div className='Infor' onClick={()=>handleClick('skills')}>
                <div className='personalTitle' >
                    <p className="current-resume-section-header">
                        <img alt="Skills Icon" src="/img/resume_skills.png" />
                        Skills
                    </p>
                    <div className="dashboard-edit-icon"/>
                    {/* <img src="/img/edit_resume_pen.png" alt="edit icon" onClick={()=>handleClick('skills')} /> */}
                </div>
                {skills && <p className='currentSkills'>{skills.join(', ')}</p>}
            </div>

            <div className='Infor' onClick={()=>handleClick('certificates')}>
                <div className='personalTitle' >
                    <p className="current-resume-section-header">
                        <img alt="Certificate Icon" src="/img/resume_certificate.png" />
                        Certificate
                    </p>
                    <div className="dashboard-edit-icon"/>
                    {/* <img src="/img/edit_resume_pen.png" alt="edit icon" onClick={()=>handleClick('certificates')} /> */}
                </div>
                {certificate && certificate.length > 0 ? (
                    certificate.map((cert, index) => (
                        <div key={index}>
                            <p className='item'> {cert.name}</p>
                            <p className='item'> {formatDate(cert.achieved_date)}</p>
                        </div>
                    ))
                ) : (
                    <p>No certificates available.</p>
                )}
                
            </div>
            
  
          
        </div>
    );
};

export default CurrentResume;
